@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@100;300;400;500;700;800;900&display=swap');

@import "./vars";
@import "./sidebar-left";
@import './nav-bar-top';
@import './tables';
@import "bootstrap";
@import 'rsuite/dist/styles/rsuite-default';
@import "input-file";

@import "~slick-carousel/slick/slick.scss"; 
@import "~slick-carousel/slick/slick-theme.scss";
@import "./custom-rsuite.scss";
@import "./select-search.scss";

body{
    //font-family: 'M PLUS 1p', sans-serif !important;
}
.col{
    min-width: auto;
}
.break-word{
    word-wrap: break-word;      /* IE 5.5-7 */
      white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
      white-space: pre-wrap; 
}
.slick-slider {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
  .slick-list.draggable {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
.fade{
    opacity: 1;
}
.slick-prev::before, .slick-next::before {
    color: black;
}
body,#root,#root > .container-fluid,.h-100{
    min-height: 100vh;
}
.bg-gray{
    background-color: $color-gray-bg
}
.width95{
    width: 95.5% !important;
}
#container-main,.container-fluid{
    background-color: $color-gray-bg;
}
.layout-main{
	margin-top: $height-top-bars;
	padding-top:15px;
}
div.black{
    background-color: $black;
    position: absolute;
    min-height: 100%;
    width: 50%;
    left: 0;
}
div.blue{
    background-color: $b4ind-blue;
    position: absolute;
    min-height: 100%;
    width: 50%;
    left: 0;
}
.text{
    &-orange{
        color:$color-orange;
    }
    &-black{
        color:$color-black;
    }
    &-errors{
        color:$color-danger
    }
}
.btn-black {
    color: $color-white;
    background-color: $b4ind-green;
    border-color: $b4ind-green;
}
.btn-black:hover {
    color: $color-white;
    background-color: $color-gray;
    border-color: $color-gray-border;
}
.brand-invert{
    -webkit-filter: invert(100%);
    -moz-filter: invert(100%);
    filter: invert(100%);
    position: absolute;
    z-index: 1;
    right: 15px;
    top: 15px;
    width: 25%;
}
.institution-logo{
    position: absolute;
    z-index: 1;
    right: 15px;
    top: 15px;
    width: 25%;
}
.b4ind-logo{
    position: absolute;
    z-index: 1;
    right: 15px;
    bottom: 15px;
    width: 10%;
}
// .ck-content{
//     height: 100vh;
// }
.template-show > .ck.ck-toolbar.ck-rounded-corners{
    position: sticky;
    z-index: 1;
    top: 9.2rem;
}
.content-ck-editor{
    position: relative;
}
.ck.ck-toolbar.ck-rounded-corners{
    position: sticky;
    z-index: 1;
    top: 4.68rem;
}
.ck-editor__editable_inline {
    min-height: 200px;
    border:1px solid #ced4da !important;
}
.input-group > .fa-info{
    width: 24px;
    height: 24px;    
    text-align: center;    
    padding: 3px;    
    border: 0.1px solid #8e9295;    
    border-radius: 50%;    
    margin-left: 12px;    
    color: #8e9295;    
    margin-top: 4px;    
    margin-bottom: 4px;
}
.t-h-250{
    height: 250px;
    margin-bottom: 15px;
    border-bottom: 1px solid #dee2e6;
    overflow: -moz-scrollbars-vertical; 
    overflow-y: scroll;
    & thead{
        position: sticky;
        top:0px;
        & th {
            position: relative;
        }
        & th:before{
            background: white;
            position: absolute;
            width: 100%;
            height: 36px;
            content: '';
            left: 0px;
            top: -1px;
            z-index: -1;
            border-bottom: 1px solid #dee2e6;
            border-top: 1px solid #dee2e6;
        }
    }
}
.line-right:after{
    content: '';
    display: block;
    width: 1px;
    background-color: #dee2e6;
    position: absolute;
    top:15%;
    bottom: 15%;
    right:25px;
}
.user-photo-25{
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #dee2e6;
    margin: auto;
}
.pagination-out-bulma {
    justify-content: initial;
}

.Toastify__toast-container {
  margin-top: 4rem;
}
.btn {
    white-space: nowrap;
    i {
        pointer-events: none;
    }
}

.keywordList {
    height: 200px;
    overflow: auto;
}
.react-datepicker-wrapper{
    display:block;
    input {
        @extend .form-control;
    }
}
td[role='cell']:last-child{
    white-space: nowrap;
}