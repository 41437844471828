.control-panel-card{
    border-style: solid;
    border-width: 1px;
    border-color: black;
    border-radius: 5px;
    padding: 10px;
}

.indicator-number{
    font-size: 20px;
    font-weight: bold;
}