$width-sidebar: 229px; //18rem;
$width-close: 5rem;
$height-top-bars:75px;

// Colores Corporativos

$b4ind-blue: #0a1e8e;
$b4ind-green: #80bb00;


$burger: white;
$color-blue: #191F28;
$color-grey: #fff;
$color-blue-0:#D1F0E9;
$color-blue-0-hover:#A6EADB5E;
$color-blue-1:#65A3AF;
$color-usplat:#F05032;
$color-border:#f0f0f0;
$color-red-notification:red;
$color-danger:#dc3545;


$color-black:black;
$color-gray:#23272b;
$color-gray-border:#1d2124;
$color-gray-bg:#F7F7FA;
$color-white:white;
$color-orange:#F05032;

$box-shadow-1:-4px 0px 12px #00000036;
