.table-custom{
    border-collapse: collapse;
    border-radius: 1em;
    overflow: hidden;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    & tbody{
        background-color: green;
    }
    & th, & tr{
        padding: 8px 15px;
    }
    & th {
        border-top: 0px solid #dee2e6 !important;
    }
}
.bg-orange{
    background-color: $color-orange;
}