// SelectPicker
.rs-picker{
    &-default &-toggle.rs-btn {
        padding-top: 6.5px;
        padding-bottom: 6.5px;
        border: 1px solid #ced4da;
        border-radius: 3px;
    }
    &-default &-toggle.rs-btn &-toggle-caret, &-default &-toggle.rs-btn &-toggle-clean {
        top: 9px;
    }
}
.rs-picker-toggle, .rs-picker-toggle .rs-picker-toggle-placeholder {
    color: #575757;
    font-size: 1rem !important;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-btn.active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
