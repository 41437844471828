.top-sidebar{
	position: absolute;
	width: 100%;
	background-color: $color-black;
	height: $height-top-bars;
	left: 0;
	z-index: -1;
	border-right: 0.1px solid $color-border;
	//border-bottom: 1px solid $color-border;
}
.bar-sidebar-left{
	width: 20px;
	display: block;
	cursor: pointer;
	float: right;
	margin: 12px 0px 0px 0px;
	height: 18px;
	&:after,&:before{
		content: "";
		width: 100%;
		height: 2px;
		background-color:$burger;
		display: flex;
		border-radius: 10px;
		margin-bottom: 4px;
		margin-top: 0px;
	}
}
.sidebar{
	&-left{
		& .collapse > ul {
			background: #dee2e6;
			padding-bottom: 15px;
			padding-left: 20px !important;
			margin-left: 0px;
			margin-right: -20px;
			margin-top: 15px;
		}
		& .icon-brand{
			display: none;
		}
    & .fa,
    .far, .fas, svg{
      	color: $b4ind-green;
		}
		& .img-fluid{
			width: 130px;
		}
		& .sidebar-icon{
		    width: 17px;
		    margin-right: 5px;
		    margin-bottom: 5px;
		    filter: invert(70%) brightness(99%) sepia(98%) saturate(9985%) invert(70%);
		}
		& .avartar-user{
			//width: 70%;
			width: 150px;
			height: 150px;
			margin-top: 25px;
			border-radius: 50%;
			object-fit: cover;
			object-position: center right;
		}
		& {
			height: 100%;
			width: $width-sidebar;
			box-shadow: $box-shadow-1;
			position: fixed;
			z-index: 1;
			top: 0;
			left: 0;
			background-color: $color-grey;
			overflow-x: hidden;
			padding-top: 0px;
			padding-left: 19px;
			padding-right: 19px;
			transition: width 0.5s;
		}
		& .li-link {
			padding: 5px 0px 5px 0px;
			border-bottom:0px;
		}
		& li.frist-brand{
			padding: 23.5px 0px 23.5px 0px;
			margin-bottom: 92px;
			text-align: center;
			& > .brand-invert{
				margin-top: 11px;
			}
		}
		& .li-link {
			border-bottom: 0.1px solid $color-border;
			cursor: pointer;
			font-size: 15px;
			color: black;
			display: block;

			&:first-of-type{
				border-top: 0px solid;
			}
		}
		& .li-link {
			& li {
				color: black;
				border-bottom: 0px solid white;
				padding: 15px 0px 0px 0px;
			}
		}
		& li:hover {
			color: $color-usplat;
		}
		& a:hover {
			color: $color-usplat;
			text-decoration: none;
		}
		& ul{
			list-style: none;
		}
	}
	&-close{
		width: $width-close;
		padding-left: 15px;
		padding-right: 15px;
		transition: width 0.5s;
		&  .top-sidebar{
			height: $height-top-bars;
		}
		& .bar-sidebar-left{
			margin: auto;
			float: none;
		}
		& .sidebar-label{
			display: none;
		}
		& li, & button{
			text-align: center;
		}
		& .icon-brand{
			display: block;
			width: 30px;
			margin: auto;
			margin-bottom: 15px;
			//margin-top: 25px;
			margin-top: 66px;
		}
		& .icon-brand-open{
			display: none;
		}
		& .avartar-user{
			margin-top: 0px;
			width: 45px;
			height: 45px;
		}
	}
}
.layout-main, .layout-footer{
  margin-left: calc(#{$width-sidebar} - 1rem);
  transition: margin-left 0.5s;
}
.layout-sidebar-close{
	margin-left: calc(#{$width-close} - 1rem);
}
.sidebar-close{
	& .bar-sidebar-left{
		margin-top: 11px;
	}
	& li.frist-brand {
		margin-bottom: 0px;
	}
	&~.nav-bar-top{
		margin-left: $width-close;
		transition: margin-left 0.5s;
	}
}
ul {
	& > button, & .bar-sidebar-left{
		background: none;
		color: inherit;
		border: none;
		padding: 0;
		font: inherit;
		cursor: pointer;
		outline: inherit;
	}
	& > button{
		text-align: left;
		width: 100%;
	}
}