.nav-bar-top {
	margin-left: $width-sidebar;
	transition: margin-left 0.5s;
    height: $height-top-bars;
    background-color: $color-black;
	box-shadow: 0px 3px 30px #0000000d;

  .user-name{
    margin-top: 3rem !important;
  }

	& .dropdown-toggle::after {
	    margin-top: 6%;
	}
	&  .user{
		&-photo{
			width: 48px;
			height: 48px;
			border-radius: 50%;
			object-fit: cover;
			&-notifications{
				width: 30px;
				height: 30px;
				border-radius: 50%;
				object-fit: cover;
			}
		}
	 	&-dropdown-top{
      &.show {
        border-radius: 0px;
        margin-top: 0px;
        border-top: 0px;
        border-color:$color-border;
      }
      .user-logout{
        color: $color-danger;
        .fa-sign-out{
          margin-right: 0.3rem;
        }
      }
    }
		&-info{
			width: 150px;
			white-space: break-spaces;
		}
		&-dropdown-toggle{
			display: inline-flex;
		}
	}
	& .notifications-top{
		height: 100%;
		align-items: center;
		& .fa-bell-o{
			height: 28px;
			width: 28px;
			color: $color-white;
		}
		&:after{
			border: 1px solid $color-red-notification;
			height: 1rem;
			//width: 1rem;
			background: $color-red-notification;
			border-radius: 5px;
			right: 25px;
			position: absolute;
			top: 20px;
			padding: 0px 5px;
			content: attr(data-count);
			font-size: 12px;
			line-height: 1;
			color: white;
		}
	}
	& .not-read:after{
		width: 10px;
		height: 10px;
		content: '';
		position: absolute;
		background-color: green;
		top: 0;
		right: 15px;
		border-radius: 50%;
	}
	& .dropdown-20rem{
		max-height: 20rem;
		overflow: auto;
	}
}