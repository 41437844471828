.input-file-custom{
	position: relative;
	cursor: pointer;
	& > input{
		width: 120px;
		border: 0px solid red;
		opacity: 0.0;
		cursor: pointer;
		height: 120px;
	}
	&:after{
		font-family: "FontAwesome";
		content:'\f03e';//fa-picture-o
		width: 120px;
		height: 120px;
		position: absolute;
		left: 0;
		border: 1px solid #ced4da;
		border-radius: 50%;
		font-size: 40px;
		padding: 25px;
		top: 0;
	}
	&:before{
		font-family: "FontAwesome";
		content: '\f067';
		width: 20px;
		height: 20px;
		position: absolute;
		right: 7px;
		font-size: 35px;
		bottom: 40px;
		font-weight: 900;
		z-index: 2;
		color: #f05032;
	}
	&:hover{
		background: #c6c6c66e;
	    border-radius: 50%;
	    color: black;
	};
	& > .preview {
		width: 100%;
		max-height: 100%;
		height: 100%;
		position: absolute;
		border-radius: 50%;
		object-fit: cover;
		left: 0;
		z-index: 1;
		top: 0;
	}
	&.on:after{
		content: '';
	}
	& .invalid-feedback{
		position: absolute;
		top: -40px;
		margin: auto;
		width: 100%;
		left: auto;
		right: auto;
	}

}