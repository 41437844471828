table{

  .read-0,
  .read-1{
    &::after{
      margin-left: 0.7rem;
    }
  }

  .read-0::after{
      font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f0e0";
      font-size: 1rem;
      color:green;
    }
  .read-1{
    opacity: 50%;

    &::after{
      font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f658"; 
      font-size: 1rem;
    }
  }
}